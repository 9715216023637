var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { AdminModeratorAPI } from "@api/API";
import { changeLoading } from "../../loaderReduser";
import { setActive, setErrorMessageToUserForModal, setMessageToUserForModal, setModal } from "../../modalReducer";
var initialStateBookmarkStatus = {
    sortModerationButton: [
        { id: 1, title: "Ожидают модерации", moderateStatus: 1, check: false },
        { id: 2, title: "Отклоненные", moderateStatus: 2, check: false },
    ],
    typeUrlForRequest: "publication/",
    moderateStatus: 3,
    searchInputValue: "",
};
var AdminModerator = createSlice({
    name: "AdminModerator",
    initialState: initialStateBookmarkStatus,
    reducers: {
        setAdminModeratePageInfo: function (state, action) {
            state.AdminModeratePageInfo = action.payload;
            state.AdminModeratePanelButtonsBlock = action.payload.right.menu.map(function (item) {
                if (item.order === 1) {
                    return __assign(__assign({}, item), { check: true });
                }
                else {
                    return __assign(__assign({}, item), { check: false });
                }
            });
        },
        setCategoriesForAdminModeratorPage: function (state, action) {
            state.categoriesForEditPost = action.payload;
        },
        setTypeRequestForAdminModeratePage: function (state, action) {
            state.typeUrlForRequest = action.payload;
        },
        setSearchInputValue: function (state, action) {
            state.searchInputValue = action.payload;
        },
        setPublicationForEditById: function (state, action) {
            state.publicationByIdForEdit = action.payload;
        },
        setActiveAdminModerateButtonBlock: function (state, action) {
            state.AdminModeratePanelButtonsBlock.map(function (button) {
                if (button.order === action.payload.order) {
                    state.typeUrlForRequest = button.url;
                    return (button.check = true);
                }
                else {
                    return (button.check = false);
                }
            });
        },
        setActiveSortModerationButton: function (state, action) {
            state.sortModerationButton.map(function (button) {
                if (button.id === action.payload.id) {
                    state.moderateStatus = button.moderateStatus;
                    return (button.check = !button.check);
                }
                else {
                    return (button.check = false);
                }
            });
        },
        setUnactiveSortModerationButton: function (state) {
            state.moderateStatus = null;
        },
        setAdminModeratorFeed: function (state, action) {
            state.AdminModeratorFeed = action.payload;
        },
        setModerateStatusChanged: function (state) {
            state.isModerateStatusChanged = !state.isModerateStatusChanged;
        },
        setModerationStatus: function (state, action) {
            state.moderateStatus = action.payload;
        },
        clearPublicationToEditForAdmin: function (state) {
            state.publicationByIdForEdit = {};
        },
        setAdminProfilePageToDefault: function (state) {
            state.moderateStatus = 3;
            state.typeUrlForRequest = "publication/";
        },
        //для модерации пользоваетелей
        setCurrentUserlist: function (state, action) {
            state.currentUserlist = action.payload;
        },
        setRequestUserlistForModeration: function (state, action) {
            state.requestsUserlistForModeration = action.payload;
        },
        setRequestUserDataById: function (state, action) {
            state.requestUserDataById = action.payload;
        },
        setClearUserByIdForRequest: function (state) {
            state.requestUserDataById = {};
        },
        setNewBanValueInStore: function (state, action) {
            state.currentUserlist.results.map(function (user) {
                if (user.id === action.payload) {
                    return (user.banned = !user.banned);
                }
                else {
                    return user.banned;
                }
            });
        },
        setChangeTrustedField: function (state, action) {
            state.currentUserlist.results.map(function (user) {
                if (user.id === action.payload.id) {
                    return (user.trusted = action.payload.isTrusted);
                }
                else {
                    return user.trusted;
                }
            });
        },
        setRefreshOrderListAfterAction: function (state, action) {
            state.requestsUserlistForModeration.results = state.requestsUserlistForModeration.results.filter(function (request) {
                return request.id !== action.payload;
            });
            state.isNeedUpdateDataOnAdminPage = !state.isNeedUpdateDataOnAdminPage;
        },
        setTypeUserAccount: function (state, action) {
            state.typeUserAccount = action.payload;
        },
        changeInfoIncomingUser: function (state, action) {
            state.requestsUserlistForModeration.results = state.requestsUserlistForModeration.results.map(function (currentUser) { return (currentUser.id === action.payload.currentId ? action.payload.updatedUser : currentUser); });
        },
        changeInfoCurrentUser: function (state, action) {
            state.currentUserlist.results = state.currentUserlist.results.map(function (currentUser) {
                return currentUser.id === action.payload.currentId ? action.payload.updatedUser : currentUser;
            });
        },
        setPublicationToModerationTriggered: function (state) {
            state.isPublicationSentToModertion = !state.isPublicationSentToModertion;
        },
    },
});
export default AdminModerator.reducer;
export var setAdminModeratePageInfo = (_a = AdminModerator.actions, _a.setAdminModeratePageInfo), setCategoriesForAdminModeratorPage = _a.setCategoriesForAdminModeratorPage, setPublicationForEditById = _a.setPublicationForEditById, setSearchInputValue = _a.setSearchInputValue, setActiveAdminModerateButtonBlock = _a.setActiveAdminModerateButtonBlock, setActiveSortModerationButton = _a.setActiveSortModerationButton, setUnactiveSortModerationButton = _a.setUnactiveSortModerationButton, setAdminModeratorFeed = _a.setAdminModeratorFeed, setModerateStatusChanged = _a.setModerateStatusChanged, setTypeRequestForAdminModeratePage = _a.setTypeRequestForAdminModeratePage, clearPublicationToEditForAdmin = _a.clearPublicationToEditForAdmin, setAdminProfilePageToDefault = _a.setAdminProfilePageToDefault, setRequestUserlistForModeration = _a.setRequestUserlistForModeration, setCurrentUserlist = _a.setCurrentUserlist, setRequestUserDataById = _a.setRequestUserDataById, setClearUserByIdForRequest = _a.setClearUserByIdForRequest, setNewBanValueInStore = _a.setNewBanValueInStore, setRefreshOrderListAfterAction = _a.setRefreshOrderListAfterAction, setTypeUserAccount = _a.setTypeUserAccount, changeInfoCurrentUser = _a.changeInfoCurrentUser, changeInfoIncomingUser = _a.changeInfoIncomingUser, setModerationStatus = _a.setModerationStatus, setChangeTrustedField = _a.setChangeTrustedField, setPublicationToModerationTriggered = _a.setPublicationToModerationTriggered;
//THUNK
export var fetchAdminModeratorFeed = function (typeUrl, moderate) { return function (dispatch) {
    if (typeUrl === "publication/" || typeUrl === "events/" || typeUrl === "vacancy/" || typeUrl === "discont/") {
        dispatch(changeLoading({ isLoading: true }));
        AdminModeratorAPI.getFeedForAdminModerator(typeUrl, moderate)
            .then(function (res) {
            dispatch(setAdminModeratorFeed(res.data));
            dispatch(changeLoading({ isLoading: false }));
        })
            .catch(function (error) {
            console.log("не получили данные о странице модератора админа" + error);
        });
    }
}; };
export var fetchAdminFeedForModerationWithPagination = function (numberPage, typeUrl, moderate, searchWord) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                if (!(typeUrl === "publication/" || typeUrl === "events/" || typeUrl === "vacancy/" || typeUrl === "startups_list/")) return [3 /*break*/, 2];
                return [4 /*yield*/, AdminModeratorAPI.getPublicationsForModerationsWithPagination(numberPage, typeUrl, moderate, searchWord)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2: return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                console.log(err_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getAdminModeratorPageInfo = function () { return function (dispatch) {
    AdminModeratorAPI.getAdminModeratePageInfo()
        .then(function (res) {
        dispatch(setAdminModeratePageInfo(res.data));
    })
        .catch(function (error) {
        console.log("не получили данные о странице модератора админа" + error);
    });
}; };
export var getPublicationByIdForEditTH = function (id) { return function (dispatch) {
    AdminModeratorAPI.getPublicationForEdit(id)
        .then(function (res) {
        dispatch(setPublicationForEditById(res.data));
    })
        .catch(function (error) {
        console.log("Текущую публикацию не получили" + error);
    });
}; };
export var editPublicationByIdTH = function (id, data) { return function (dispatch) {
    AdminModeratorAPI.getEditPublication(id, data)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        setTimeout(function () {
            window.history.back();
        }, 100);
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var editPublicationByIdFromCard = function (id, data) { return function (dispatch) {
    AdminModeratorAPI.getEditPublication(id, data)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var setModerateStatusForPublicationByIdTH = function (id, moderateStatus) { return function (dispatch) {
    AdminModeratorAPI.getEditPublication(id, {
        moderate: moderateStatus,
    })
        .then(function (res) {
        dispatch(setPublicationToModerationTriggered());
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var setModerateStatusForVacancyByIdTH = function (id, moderateStatus) { return function (dispatch) {
    AdminModeratorAPI.getEditVacancyModerationStatus(id, {
        moderate: moderateStatus,
    })
        .then(function (res) {
        if (res.status === 201) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var setModerateStatusForDiscontByIdTH = function (id, moderateStatus) { return function (dispatch) {
    AdminModeratorAPI.getEditDiscontModerationStatus(id, {
        moderate: moderateStatus,
    })
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var fetchCategoriesForAdminModeratorPage = function () { return function (dispatch) {
    AdminModeratorAPI.getCategoriesAdminModeratorPage()
        .then(function (res) {
        dispatch(setCategoriesForAdminModeratorPage(res === null || res === void 0 ? void 0 : res.data));
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
        console.log("не получили данные о странице модератора админа" + error);
    });
}; };
// thunks для модерации пользователей
export var fetchUserlistForModetation = function (userType) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    AdminModeratorAPI.getCompanysUserlist(userType)
        .then(function (res) {
        dispatch(setCurrentUserlist(res.data));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("не получили данные о странице модератора админа" + error);
    });
}; };
export var fetchNewOrderlistForModeration = function (typeRequest) { return function (dispatch) {
    // dispatch(changeLoading({ isLoading: true }));
    AdminModeratorAPI.getNewOrdersFromUsers(typeRequest)
        .then(function (res) {
        dispatch(setRequestUserlistForModeration(res.data));
        // dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("не получили данные о странице модератора админа" + error);
    });
}; };
export var getCreateUserByAdmin = function (data) { return function (dispatch) {
    AdminModeratorAPI.createUserByAdmin(data)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
        console.log("не создали юзера" + error);
    });
}; };
export var getWatchRequestById = function (id) { return function (dispatch) {
    AdminModeratorAPI.getWatchRequest(id)
        .then(function (res) {
        dispatch(setRequestUserDataById(res.data));
    })
        .catch(function (error) {
        console.log("не создали юзера" + error);
    });
}; };
export var getBanOrUnbanUserById = function (id) { return function (dispatch) {
    AdminModeratorAPI.getBanUser(id)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        console.log("не создали юзера" + error);
    });
}; };
export var getAcceptOrDeclineOrderById = function (id, status) { return function (dispatch) {
    AdminModeratorAPI.getAcceptOrDeclineOrder(id, status)
        .then(function (res) {
        dispatch(setRefreshOrderListAfterAction(id));
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
        console.log("не создали юзера" + error);
    });
}; };
export var updateUserDataByAdmin = function (id, userUpdateData) { return function (dispatch) {
    AdminModeratorAPI.getEditUserByAdmin(id, userUpdateData)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        // dispatch(toggleAvatarOrCoverChanged());
        // dispatch(setAuthInformationUser(res.data));
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var changeThemeThunk = function (theme) { return function (dispatch) {
    AdminModeratorAPI.changeTheme(theme)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
